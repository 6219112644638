import { useEffect, useState } from "react";

import ActiveCallDetail from "./components/ActiveCallDetail";
import Button from "./components/base/Button";
import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "./utils";

const vapi = new Vapi("5fdcaffd-d4c0-4180-a37d-dd9998e90eeb");

const ASSISTANTS = {
  bobthurman: {
    id: "5470c78c-14e8-45c9-9bcc-06893e164f58",
    name: "Bob Thurman",
  },
  tonyrobbins: {
    id: "fdcf7941-8b23-425d-af53-066787d8935c",
    name: "Tony Robbins",
  },
  emilyoster: {
    id: "e64b614f-927e-4fc0-b560-718bf97fa2ed",
    name: "Emily Oster",
  },
  claudia: {
    id: "0178c41a-14e4-4dd8-92aa-12650ed2615c",
    name: "Claudia",
  },
  drjuanspanish: {
    id: "ee59874d-ab33-4e68-a45a-b849472ce84a",
    name: "Dr Juan - Spanish",
  },
  drjuanenglish: {
    id: "58054909-8e62-4229-8a2c-d9870fe5b328",
    name: "Dr Juan - English",
  },
  samharris: {
    id: "ff7678bd-bb81-4232-8e27-1ad3faccf7a0",
    name: "Sam Harris",
  },
  tonyrobbins2: {
    id: "0875703a-f7f2-4814-9d4a-ed7ce41cd544",
    name: "Tony Robbins",
  },
  estherperel: {
    id: "c5b0052b-e02d-4a46-9d68-6b96591e9de1",
    name: "Esther Perel",
  },
  michaelshellenberger: {
    id: "d6146ae9-2391-422c-8b19-6a25372f0604",
    name: "Michael Shellenberger",
  },
  joedispenza: {
    id: "655c4b53-89e4-4f61-bbbe-45e4d145262a",
    name: "Joe Dispenza",
  },
  scottgalloway: {
    id: "0ff41a99-33ca-46d5-8fda-917e3674296f",
    name: "Scott Galloway",
  },
  tombrady: {
    id: "679fe56b-7645-41e8-bd28-74180eebc1dc",
    name: "Tom Brady",
  },
  jeffross: {
    id: "bd61da68-54c8-486b-92ca-992f0eea07dc",
    name: "Jeff Ross",
  },
  richroll: {
    id: "32fce45f-c1ae-46dc-8f66-4a41c26a92c7",
    name: "Rich Roll",
  },
  jimkwik: {
    id: "839e676a-df5c-41b3-87a3-b650f8752834",
    name: "Jim Kwik",
  },
  drshefali: {
    id: "639c7206-f7ca-4b77-a29c-4edd3b70cfd4",
    name: "Dr Shefali",
  },
  suzeorman: {
    id: "26e1d90c-fbba-48d8-995a-9926654519e3",
    name: "Suze Orman",
  },
  gabbybernstein1: {
    id: "6d8ebc0b-3515-41cd-a1a4-3dc207b00b30",
    name: "Gabby Bernstein",
  },
  gabbybernstein2: {
    id: "a41e7cf0-8475-4df4-8a96-e4b78e410e2b",
    name: "Gabby Bernstein",
  },
  rickrubin: {
    id: "1ecbc373-4d5d-47b1-8180-9c952e5bbfea",
    name: "Rick Rubin",
  },
  estherpereleducation: {
    id: "fcb37960-d172-4339-ae7e-d74801614c9d",
    name: "Esther Perel",
  },
};

const App = () => {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [muted, setMuted] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);

  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } =
    usePublicKeyInvalid();

  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      console.error(error);

      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call start handler
  const startCallInline = () => {
    const assistant = ASSISTANTS[password];
    if (!assistant) {
      setError("Invalid password");
      return;
    }
    setConnecting(true);
    vapi.start(assistant.id);
  };

  const endCall = () => {
    vapi.stop();
  };

  const toggleMute = () => {
    const newMutedState = !muted;
    setMuted(newMutedState);
    vapi.setMuted(newMutedState);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!connected ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <input
            type="text"
            placeholder="Enter name (no spaces, no capital letters)"
            value={password}
            onChange={(e) =>
              setPassword(e.target.value.toLowerCase().replace(/\s/g, ""))
            }
            style={{
              padding: "8px 20px",
              borderRadius: "8px",
              border: "1px solid #ccc",
              fontSize: "16px",
              width: "100%",
              boxSizing: "border-box",
              outline: "none",
              transition: "border-color 0.2s ease",
              "&:focus": {
                borderColor: "#6366f1",
              },
            }}
          />

          {error && <div style={{ color: "red" }}>{error}</div>}
          <Button
            label={
              ASSISTANTS[password]
                ? `Talk to ${ASSISTANTS[password].name}`
                : "Provide a name (no spaces, no capital letters)"
            }
            onClick={startCallInline}
            isLoading={connecting}
          />
        </div>
      ) : (
        <div>
          <ActiveCallDetail
            assistantIsSpeaking={assistantIsSpeaking}
            volumeLevel={volumeLevel}
            onEndCallClick={endCall}
            muted={muted}
            toggleMute={toggleMute}
          />
        </div>
      )}

      {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
      {/* <ReturnToDocsLink /> */}
    </div>
  );
};

const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] =
    useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Hi, try again.
    </div>
  );
};

const ReturnToDocsLink = () => {
  return (
    <a
      href="https://jennai.com"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        top: "25px",
        right: "25px",
        padding: "5px 10px",
        color: "#fff",
        textDecoration: "none",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Visit our site
    </a>
  );
};

export default App;
